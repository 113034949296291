import AndroidRounded from '@mui/icons-material/AndroidRounded';
import CollectionsRounded from '@mui/icons-material/CollectionsRounded';
import ConstructionRounded from '@mui/icons-material/ConstructionRounded';
import DashboardRounded from '@mui/icons-material/DashboardRounded';
import EmojiEventsRounded from '@mui/icons-material/EmojiEventsRounded';
import ParaglidingRounded from '@mui/icons-material/ParaglidingRounded';
import RocketLaunchRounded from '@mui/icons-material/RocketLaunchRounded';
import StorageRounded from '@mui/icons-material/StorageRounded';
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import Link from 'next/link';

import { GoHome } from 'components/nav/home';
import { ProjectInfo } from 'components/project/info';
import { ProjectTab, Router } from 'core/router';

interface ProjectDrawerContentProps {
  handle: string;
  selectedTab: ProjectTab;
}

export const ProjectDrawerContent = ({
  handle,
  selectedTab,
}: ProjectDrawerContentProps) => (
  <div>
    <Toolbar>
      <GoHome />
    </Toolbar>
    <Divider />
    <ProjectInfo handle={handle} />
    <Divider />
    <List>
      <Link href={`/${handle}${Router.PROJECT_DASHBOARD}`} passHref>
        <ListItemButton selected={selectedTab === ProjectTab.DASHBOARD}>
          <ListItemIcon>
            <DashboardRounded />
          </ListItemIcon>
          <ListItemText>Dashboard</ListItemText>
        </ListItemButton>
      </Link>
      <Link href={`/${handle}${Router.PROJECT_MISSIONS}`} passHref>
        <ListItemButton selected={selectedTab === ProjectTab.MISSIONS}>
          <ListItemIcon>
            <RocketLaunchRounded />
          </ListItemIcon>
          <ListItemText>Missions</ListItemText>
        </ListItemButton>
      </Link>
      <Link href={`/${handle}${Router.PROJECT_TOURNAMENTS}`} passHref>
        <ListItemButton selected={selectedTab === ProjectTab.TOURNAMENTS}>
          <ListItemIcon>
            <EmojiEventsRounded />
          </ListItemIcon>
          <ListItemText>Tournaments</ListItemText>
        </ListItemButton>
      </Link>
      <Link href={`/${handle}${Router.PROJECT_PARACHUTE}`} passHref>
        <ListItemButton selected={selectedTab === ProjectTab.PARACHUTE}>
          <ListItemIcon>
            <ParaglidingRounded />
          </ListItemIcon>
          <ListItemText>Parachute</ListItemText>
        </ListItemButton>
      </Link>
      <Link href={`/${handle}${Router.PROJECT_MARKETS}`} passHref>
        <ListItemButton selected={selectedTab === ProjectTab.MARKETS} disabled>
          <ListItemIcon>
            <ConstructionRounded />
          </ListItemIcon>
          <ListItemText>Markets</ListItemText>
        </ListItemButton>
      </Link>
      <Divider />
      <Link href={`/${handle}${Router.PROJECT_COLLECTIONS}`} passHref>
        <ListItemButton selected={selectedTab === ProjectTab.COLLECTIONS}>
          <ListItemIcon>
            <AndroidRounded />
          </ListItemIcon>
          <ListItemText>Collections</ListItemText>
        </ListItemButton>
      </Link>
      <Link href={`/${handle}${Router.PROJECT_DATA}`} passHref>
        <ListItemButton selected={selectedTab === ProjectTab.DATA}>
          <ListItemIcon>
            <StorageRounded />
          </ListItemIcon>
          <ListItemText>Data</ListItemText>
        </ListItemButton>
      </Link>
      <Link href={`/${handle}${Router.PROJECT_MEDIA}`} passHref>
        <ListItemButton selected={selectedTab === ProjectTab.MEDIA}>
          <ListItemIcon>
            <CollectionsRounded />
          </ListItemIcon>
          <ListItemText>Media</ListItemText>
        </ListItemButton>
      </Link>
      <Divider />
      <Link href={`/${handle}${Router.PROJECT_APIS}`} passHref>
        <ListItemButton selected={selectedTab === ProjectTab.APIS} disabled>
          <ListItemIcon>
            <ConstructionRounded />
          </ListItemIcon>
          <ListItemText>APIs</ListItemText>
        </ListItemButton>
      </Link>
      <Link href={`/${handle}${Router.PROJECT_SDKS}`} passHref>
        <ListItemButton selected={selectedTab === ProjectTab.SDKS} disabled>
          <ListItemIcon>
            <ConstructionRounded />
          </ListItemIcon>
          <ListItemText>SDKs</ListItemText>
        </ListItemButton>
      </Link>
    </List>
  </div>
);
