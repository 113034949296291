import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

export type { AuthError, User as AuthUser } from 'firebase/auth';
export { FirebaseError } from '@firebase/util';
export { AuthErrorCodes } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyA2yZ4MMl9zz4t4lBSwgph_GvT-pJo6KtE',
  appId: '1:142197660948:web:760538cc3761f2cc9a7601',
  authDomain: 'auth.hottub.fractal.is',
  messagingSenderId: '142197660948',
  projectId: 'dazzling-reach-335222',
  storageBucket: 'dazzling-reach-335222.appspot.com',
};

const firebaseApp = initializeApp(firebaseConfig);
export default getAuth(firebaseApp);
