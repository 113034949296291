import AdjustIcon from '@mui/icons-material/Adjust';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import RedeemIcon from '@mui/icons-material/Redeem';
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { GoHome } from 'components/nav/home';
import { UserInfo } from 'components/user/user-info';
import { Router } from 'core/router';
import { isNullOrUndefined } from 'lib/util/guards';

interface UserDrawerContentProps {
  userId: string;
}

export const UserDrawerContent = ({ userId }: UserDrawerContentProps) => {
  const pathname = useRouter().pathname;

  if (isNullOrUndefined(userId)) {
    throw new Error('Expected userId to be defined on user.');
  }

  return (
    <div>
      <Toolbar>
        <GoHome />
      </Toolbar>
      <Divider />
      <UserInfo userId={userId} />
      <Divider />
      <List>
        <Link href={`/user/${userId}${Router.USER_FPOINTS}`} passHref>
          <ListItemButton
            selected={pathname.endsWith(`/[userId]${Router.USER_FPOINTS}`)}
          >
            <ListItemIcon>
              <AdjustIcon />
            </ListItemIcon>
            <ListItemText>FPoints</ListItemText>
          </ListItemButton>
        </Link>
        <Link href={`/user/${userId}${Router.USER_REWARDS}`} passHref>
          <ListItemButton
            selected={pathname.endsWith(`/[userId]${Router.USER_REWARDS}`)}
          >
            <ListItemIcon>
              <RedeemIcon />
            </ListItemIcon>
            <ListItemText>Rewards</ListItemText>
          </ListItemButton>
        </Link>
        <Link href={`/user/${userId}${Router.USER_MANAGE}`} passHref>
          <ListItemButton
            selected={pathname.endsWith(`/[userId]${Router.USER_MANAGE}`)}
          >
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText>Manage</ListItemText>
          </ListItemButton>
        </Link>
      </List>
    </div>
  );
};
