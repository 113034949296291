export const isNotNullOrUndefined = <T>(
  value: T | null | undefined,
): value is T => {
  return value != null;
};

export const isNullOrUndefined = <T>(
  value: T | null | undefined,
): value is null | undefined => {
  return value == null;
};

export const isEnum =
  <T>(e: T) =>
  (token: unknown): token is T[keyof T] =>
    Object.values(e).includes(token as T[keyof T]);
