import { Menu as MenuIcon, Public as PublicIcon } from '@mui/icons-material';
import { AppBar, IconButton, Stack, Toolbar, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useRouter } from 'next/router';

import { Account } from 'components/nav/account';
import { DRAWER_WIDTH } from 'components/nav/drawer/base-drawer';
import { CreateProject } from 'components/project/core/create';
import { ProjectSearch } from 'components/search/project-search';
import { UserSearch } from 'components/user/user-search';
import { Router } from 'core/router';

interface Props {
  handleDrawerToggle: () => void;
}

export const NavBar = ({ handleDrawerToggle }: Props) => {
  const router = useRouter();

  const handleGlobalClick = () => {
    router.push(Router.GLOBAL);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        ml: { sm: `${DRAWER_WIDTH}px` },
        width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Stack alignItems="center" justifyContent="start" flexDirection="row">
          <IconButton
            size="large"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' }, mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        </Stack>
        <Stack
          justifyContent="flex-end"
          alignItems="center"
          direction="row"
          spacing={2}
          sx={{ flexGrow: 1 }}
        >
          <Box
            sx={{
              maxWidth: { sm: 360 },
            }}
            flexGrow={1}
          >
            <UserSearch small />
          </Box>
          <Box
            sx={{
              maxWidth: { sm: 360 },
            }}
            flexGrow={1}
          >
            <ProjectSearch autoFocus small placeholder="Project" />
          </Box>
          <Box flexGrow={0}>
            <CreateProject />
          </Box>
          <Tooltip title="Global">
            <IconButton size="large" edge="end" onClick={handleGlobalClick}>
              <PublicIcon />
            </IconButton>
          </Tooltip>
          <Account />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
