import * as Sentry from '@sentry/nextjs';
import { getAuth, signOut } from 'firebase/auth';

export const logout = async () => {
  try {
    await signOut(getAuth());
  } catch (error: unknown) {
    Sentry.captureException(error);
  }
};
