import { Api as BathhausApi } from '@fractalwagmi/bathhaus';
import { Api } from '@fractalwagmi/fractal';
import { Api as AdminApi } from '@fractalwagmi/fractal-admin';

const ADMIN_API_ROOT_URL = 'https://api.hottub.fractal.is';
const BATHHAUS_API_ROOT_URL = 'https://api.hottub.fractal.is';
const MAIN_API_ROOT_URL = 'https://api.fractal.is';

export const adminApiClient = new AdminApi({
  baseApiParams: {
    referrerPolicy: 'strict-origin-when-cross-origin',
  },
  baseUrl: ADMIN_API_ROOT_URL,
});
export const bathhausApiClient = new BathhausApi({
  baseApiParams: {
    referrerPolicy: 'strict-origin-when-cross-origin',
  },
  baseUrl: BATHHAUS_API_ROOT_URL,
});
export const mainApiClient = new Api({
  baseApiParams: {
    referrerPolicy: 'strict-origin-when-cross-origin',
  },
  baseUrl: MAIN_API_ROOT_URL,
});
