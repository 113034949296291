import useSWR from 'swr';

import {
  ENDPOINTS,
  getPendingProjectReviews,
  getProjectByIdFetcher,
  getProjectFetcher,
  getProjectReviewLogs,
  getStudioByIdFetcher,
  searchStudiosFetcher,
} from 'core/api/endpoints';

export const useProject = (handle: string) => {
  const { data, error } = useSWR(
    [handle, ENDPOINTS.GET_PROJECT],
    getProjectFetcher,
  );

  return {
    data,
    error,
    loading: !error && !data,
  };
};

export const useProjectById = (id: string) => {
  const { data, error } = useSWR(
    [id, ENDPOINTS.GET_PROJECT_BY_ID],
    getProjectByIdFetcher,
  );

  return { data, error, loading: !error && !data };
};

export const useStudioHandleByProjectHandle = (handle: string) => {
  const { data: project } = useProject(handle);
  const { data, error } = useSWR(
    project ? [handle, ENDPOINTS.GET_STUDIO_BY_PROJECT_HANDLE] : null,
    async () => getStudioByIdFetcher(project?.studioId ?? ''),
  );

  return { data, error, loading: !error && !data };
};

export const useProjectReviewLogs = (projectId: string) => {
  const { data, error } = useSWR(
    projectId ? [projectId, ENDPOINTS.GET_PROJECT_REVIEW_LOGS] : null,
    getProjectReviewLogs,
  );

  return { data, error, loading: !error && !data };
};

export const useSearchStudios = (query: string) => {
  const { data, error } = useSWR(
    [query, ENDPOINTS.SEARCH_STUDIOS],
    searchStudiosFetcher,
  );

  return {
    data,
    error,
  };
};

export const usePendingProjectReviews = () => {
  const { data, error } = useSWR(
    [ENDPOINTS.GET_PENDING_PROJECT_REVIEWS],
    getPendingProjectReviews,
  );

  return { data, error };
};
