import { useQuery } from '@tanstack/react-query';

import { adminApiClient } from 'core/api/client';
import { getRequestParams } from 'core/api/endpoints';
import { captureFractalException } from 'core/error/capture-fractal-exception';

const UserQueryKey = {
  GetUserById: 'UserQueryKeyGetUserById',
  SearchUser: 'UserQueryKeySearchUser',
} as const;

export const UserApiKeys = {
  getUserById: (userId: string) => [UserQueryKey.GetUserById, userId] as const,
  searchUser: (query: string) => [UserQueryKey.SearchUser, query] as const,
} as const;

export const UserApi = {
  getUserById,
  searchUser,
};

export const useUserById = (userId: string) => {
  return useQuery(UserApiKeys.getUserById(userId), async () =>
    UserApi.getUserById(userId),
  );
};

export const useSearchUserQuery = (query: string) => {
  return useQuery(
    UserApiKeys.searchUser(query),
    async () => UserApi.searchUser(query),
    {
      enabled: query.length > 3,
    },
  );
};

async function getUserById(userId: string) {
  try {
    const response = await adminApiClient.user.getUserById(
      userId,
      await getRequestParams(),
    );
    return response.data;
  } catch (err: unknown) {
    captureFractalException(err);
    throw err;
  }
}

async function searchUser(query: string) {
  try {
    const response = await adminApiClient.users.searchUser(
      { query },
      await getRequestParams(),
    );
    return response.data;
  } catch (err: unknown) {
    captureFractalException(err);
    throw err;
  }
}
