import { createContext } from 'react';

import { AuthUser } from 'core/auth/firebase';

interface AuthContextType {
  loading: boolean;
  user: AuthUser | null;
}
export const AuthContext = createContext<AuthContextType>({
  loading: true,
  user: null,
});
