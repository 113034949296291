import { isEnum } from 'lib/util/guards';

export enum Router {
  ACCOUNT = '/account',
  GLOBAL = '/global',
  HOME = '/',
  PROJECT_REVIEWS = '/global/project-reviews',
  REWARDS = '/global/rewards',
  FEATURED_EVENTS = '/global/game-discovery/featured-events',
  PLAYLISTS = '/global/game-discovery/playlists',
  PROJECT_APIS = '/apis',
  PROJECT_COLLECTIONS = '/collections',
  PROJECT_DASHBOARD = '',
  PROJECT_DATA = '/data',
  PROJECT_MARKETS = '/markets',
  PROJECT_MEDIA = '/media',
  PROJECT_MISSIONS = '/missions',
  PROJECT_SDKS = '/sdks',
  PROJECT_PARACHUTE = '/parachute',
  PROJECT_TOURNAMENTS = '/tournaments',
  USER_FPOINTS = '/fpoints',
  USER_MANAGE = '/manage',
  USER_REWARDS = '/rewards',
}

export enum ProjectTab {
  APIS = 'apis',
  COLLECTIONS = 'collections',
  DASHBOARD = 'dashboard',
  DATA = 'data',
  MARKETS = 'markets',
  MEDIA = 'media',
  MISSIONS = 'missions',
  SDKS = 'sdks',
  PARACHUTE = 'parachute',
  TOURNAMENTS = 'tournaments',
}

export enum GlobalTab {
  PROJECTREVIEWS = 'project-reviews',
  REWARDS = 'rewards',
  GAME_DISCOVERY = 'game-discovery',
  FEATURED_EVENTS = 'featured-events',
  PLAYLISTS = 'playlists',
  USER_ADMIN = 'user-admin',
}

export const isValidGlobalTab = isEnum(GlobalTab);

export const isValidProjectTab = isEnum(ProjectTab);
