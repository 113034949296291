const FORBIDDEN_CHARS = /[^a-z\d-]/;

/** Handles must be composed of lower case, alpha-numeric and hyphen characters only. */
export const validateHandle = (handle: string): boolean => {
  if (handle.length === 0) {
    return false;
  }
  return !FORBIDDEN_CHARS.test(handle);
};

export const HANDLE_VALIDATOR_ERROR_MSG =
  'Must only use lowercase letters, numbers, and hyphens.';
