import {
  ManageAccountsRounded,
  PersonRounded,
  VpnKeyOffRounded,
} from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import Link from 'next/link';
import { MouseEvent, useState, useContext } from 'react';

import { AuthContext } from 'components/auth/context';
import { logout } from 'core/auth/utils';
import { Router } from 'core/router';

export function Account() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;
  const [loggingOut, setLoggingOut] = useState(false);
  const { user } = useContext(AuthContext);

  const doLogout = async () => {
    setLoggingOut(true);
    await logout();
    setLoggingOut(false);
  };
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton size="large" edge="end" onClick={handleClick}>
        <ManageAccountsRounded />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Link href={Router.ACCOUNT} passHref>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PersonRounded />
            </ListItemIcon>
            <ListItemText>My account</ListItemText>
          </MenuItem>
        </Link>
        <MenuItem disabled={!!(loggingOut && user)} onClick={doLogout}>
          <ListItemIcon>
            {loggingOut && user ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <VpnKeyOffRounded />
            )}
          </ListItemIcon>
          <ListItemText>Sign out</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
