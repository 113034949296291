import { useRouter } from 'next/router';

import { BaseDrawer } from 'components/nav/drawer/base-drawer';
import { ProjectDrawerContent } from 'components/nav/drawer/project-drawer-content';
import { GenericErrors } from 'core/api/errors/generic';
import { isValidProjectTab, ProjectTab } from 'core/router';

export const DRAWER_WIDTH = 240;

interface ProjectDrawerProps {
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
}

export const ProjectDrawer = ({
  handleDrawerToggle,
  mobileOpen,
}: ProjectDrawerProps) => {
  const router = useRouter();
  const handle = router.query.handle as string;
  const paths = router.pathname.split('/');
  let tab: ProjectTab;
  if (paths.length === 2) {
    tab = ProjectTab.DASHBOARD;
  } else {
    if (paths.length < 3) {
      throw new Error(GenericErrors.INVALID_ROUTE);
    }
    const tabString = paths[2];
    if (!isValidProjectTab(tabString)) {
      throw new Error(GenericErrors.INVALID_ROUTE);
    }
    tab = tabString;
  }

  return (
    <BaseDrawer
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      drawerContent={<ProjectDrawerContent selectedTab={tab} handle={handle} />}
    />
  );
};
