import { useRouter } from 'next/router';

import { BaseDrawer } from 'components/nav/drawer/base-drawer';
import { UserDrawerContent } from 'components/nav/drawer/user-drawer-content';
import { parseQueryValueAsString } from 'core/router/utils';

export const DRAWER_WIDTH = 240;

interface UserDrawerProps {
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
}

export const UserDrawer = ({
  handleDrawerToggle,
  mobileOpen,
}: UserDrawerProps) => {
  const router = useRouter();
  const userId = parseQueryValueAsString(router.query.userId);

  return (
    <BaseDrawer
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      drawerContent={<UserDrawerContent userId={userId} />}
    />
  );
};
