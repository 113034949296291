import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Stack,
  useMediaQuery,
  useTheme,
  Autocomplete,
} from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { Field } from 'components/core/input/field';
import { adminApiClient, bathhausApiClient } from 'core/api/client';
import { getRequestParams } from 'core/api/endpoints';
import { useSearchStudios } from 'core/api/fetch/project';
import { Router } from 'core/router';
import {
  HANDLE_VALIDATOR_ERROR_MSG,
  validateHandle,
} from 'core/string/validators';

interface StudioOptionType {
  id?: string;
  inputValue?: string;
  name: string;
}

export function CreateProject() {
  const router = useRouter();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [projectHandle, setProjectHandle] = useState('');
  const [projectHandleError, setProjectHandleError] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [studioValue, setStudioValue] = useState<StudioOptionType | null>(null);
  const [studioOptions, setStudioOptions] = useState<StudioOptionType[]>([]);
  const [studioInput, setStudioInput] = useState('');
  const { data: studios } = useSearchStudios(studioInput);

  useEffect(() => {
    const newOptions = studios?.map(s => {
      return {
        id: s.StudioId,
        name: s.Name,
      };
    });
    setStudioOptions(newOptions ?? []);
  }, [studios]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const createProject = async () => {
    setCreating(true);
    try {
      const requestParams = await getRequestParams();

      let studioId = studioValue?.id;
      if (!studioId) {
        studioId = (
          await adminApiClient.studio.createStudio(
            {
              name: studioValue?.name ?? '',
            },
            requestParams,
          )
        ).data.id;
      }

      await bathhausApiClient.project.createProject(
        { handle: projectHandle, studioId, title },
        { ...requestParams },
      );
      if (projectHandle) {
        router.push(`/${projectHandle}${Router.PROJECT_DASHBOARD}`);
      }
    } catch (e: unknown) {
      setErrorOpen(true);
    }
    setCreating(false);
  };

  return (
    <Stack sx={{ alignItems: 'center' }}>
      {desktop ? (
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ whiteSpace: 'nowrap' }}
        >
          Create Project
        </Button>
      ) : (
        <IconButton
          size="large"
          edge="end"
          color="primary"
          onClick={handleClickOpen}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create Project</DialogTitle>
        <DialogContent>
          <Autocomplete<StudioOptionType, false, false, true>
            value={studioValue}
            onInputChange={(_, newInputValue) => {
              setStudioInput(newInputValue);
            }}
            onChange={(_, newValue) => {
              if (typeof newValue === 'string') {
                setStudioValue({
                  name: newValue,
                });
              } else if (newValue?.inputValue) {
                setStudioValue({
                  name: newValue.inputValue,
                });
              } else {
                setStudioValue(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const { inputValue } = params;
              const isExisting = options.some(
                option =>
                  inputValue.toLowerCase() === option.name.toLowerCase(),
              );
              if (inputValue !== '' && !isExisting) {
                // studio does not exist
                options.push({
                  inputValue,
                  name: `Create "${inputValue}"`,
                });
              }

              return options;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="studio-select-or-create"
            options={studioOptions}
            getOptionLabel={option => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.name;
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            freeSolo
            renderInput={params => (
              <Field
                {...params}
                sx={{ mt: 2 }}
                required
                fullWidth
                label="Studio"
              />
            )}
          />
          <Field
            sx={{ mt: 2 }}
            required
            value={title}
            fullWidth
            helperText={!title ? 'Required field.' : ''}
            label={'Title'}
            onChange={e => {
              setTitle(e.target.value);
            }}
          />
          <Field
            sx={{ mt: 2 }}
            required
            value={projectHandle}
            fullWidth
            error={!!projectHandleError}
            helperText={projectHandleError ? projectHandleError : ''}
            label={'Handle'}
            onChange={e => {
              setProjectHandle(e.target.value);
              if (validateHandle(e.target.value)) {
                setProjectHandleError('');
              } else {
                setProjectHandleError(HANDLE_VALIDATOR_ERROR_MSG);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={creating} onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={creating || !validateHandle(projectHandle) || !title}
            onClick={createProject}
            variant="contained"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert
          onClose={handleErrorClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          Failed to create project.
        </Alert>
      </Snackbar>
    </Stack>
  );
}
