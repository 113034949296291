import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import {
  Avatar,
  Box,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { ApprovalStatusChip } from 'components/project/approval-status-chip';
import {
  useProject,
  useProjectReviewLogs,
  useStudioHandleByProjectHandle,
} from 'core/api/fetch/project';
import { ProjectReviewLogStatus } from 'core/api/types';
import { ProjectHandleProps } from 'core/dom/types';
import { DEFAULT_SPACING } from 'styles/material';

export function ProjectInfo({ handle }: ProjectHandleProps) {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const { data: studio } = useStudioHandleByProjectHandle(handle);

  const { data, error } = useProject(handle);
  const { data: prls, error: prlsError } = useProjectReviewLogs(data?.id ?? '');

  if (error || !data || prlsError || !prls) {
    return <></>;
  }

  return (
    <Stack
      sx={{
        alignItems: 'center',
        mb: desktop ? 0 : DEFAULT_SPACING,
        p: 2,
        width: '100%',
      }}
    >
      <Box position="relative">
        <Avatar src={data.avatar?.url} sx={{ height: 100, width: 100 }} />
        <ApprovalStatusChip
          approvalStatus={
            (prls && prls.length > 0 && prls[0].status) ||
            ProjectReviewLogStatus.UNKNOWN
          }
          chipProps={{
            sx: {
              border: '2px solid',
              borderColor: 'background.default',
              position: 'absolute',
              right: 0,
              top: theme.typography.pxToRem(6),
              transform: 'translateX(25%)',
            },
          }}
        />
      </Box>
      <Typography variant="h5" sx={{ mt: 2, textAlign: 'center' }}>
        {data.title}
      </Typography>
      <Link
        sx={{ mt: 1 }}
        className="text-sm"
        target="_blank"
        rel="noreferrer"
        href={`https://fractal.is/${handle}`}
      >
        fractal.is/{handle}
      </Link>
      {studio && (
        <Link
          sx={{ mt: 1 }}
          className="text-sm"
          target="_blank"
          rel="noreferrer"
          href={`https://studio.fractal.is/studio/${studio.handle}/game/${handle}/details`}
        >
          studio <LaunchRoundedIcon fontSize="small" />
        </Link>
      )}
    </Stack>
  );
}
