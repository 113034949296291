import { User } from '@firebase/auth';
import { FC, useEffect, useState } from 'react';

import { AuthContext } from 'components/auth/context';
import firebaseAuth from 'core/auth/firebase';

export const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(firebaseUser => {
      setUser(firebaseUser);
      setLoading(false);
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
