import {
  Autocomplete,
  Avatar,
  CircularProgress,
  TextField,
} from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useRef, useEffect, useState } from 'react';

import { adminApiClient } from 'core/api/client';
import { getRequestParams } from 'core/api/endpoints';
import { SearchProject } from 'core/api/types';

const SEARCH_LIMIT = 5;

interface Props {
  autoFocus?: boolean;
  label?: string;
  onProjectDeselected?: () => void;
  onProjectSelected?: (project: SearchProject) => void;
  placeholder?: string;
  small?: boolean;
}

export function ProjectSearch({
  autoFocus,
  label,
  onProjectDeselected,
  onProjectSelected,
  placeholder,
  small,
}: Props) {
  const router = useRouter();
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState<SearchProject[]>([]);
  const [loading, setLoading] = useState(false);
  const inputElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleCmdK = (e: KeyboardEvent) => {
      if (e.code === 'KeyK' && e.metaKey) {
        inputElement.current?.focus();
      }
    };

    document.addEventListener('keydown', handleCmdK);

    return () => {
      document.removeEventListener('keydown', handleCmdK);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (query === '') {
        setOptions([]);
        return;
      }
      setLoading(true);
      try {
        const requestParams = await getRequestParams();
        const projects = await adminApiClient.v2.searchProjects(
          { limit: SEARCH_LIMIT, query },
          requestParams,
        );
        setOptions(projects.data.projects ?? []);
      } catch (error: unknown) {
        Sentry.captureException(error);
      }
      setLoading(false);
    })();
  }, [query]);

  return (
    <Autocomplete
      size={small ? 'small' : 'medium'}
      fullWidth
      filterOptions={x => x}
      autoComplete
      autoHighlight
      blurOnSelect
      getOptionLabel={option => option.title ?? ''}
      options={options}
      loading={loading}
      noOptionsText="No results"
      inputValue={query}
      isOptionEqualToValue={(option, value) => option.handle === value.handle}
      onChange={(
        _event: React.SyntheticEvent<Element, Event>,
        newValue?: SearchProject | null,
      ) => {
        if (newValue?.handle) {
          if (onProjectSelected) {
            onProjectSelected(newValue);
          } else {
            router.push(`/${newValue.handle}`);
          }
        } else {
          onProjectDeselected?.();
        }
      }}
      onInputChange={(_, newInputValue) => {
        setQuery(newInputValue);
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <div className="flex items-center">
            <Avatar src={option.avatarUrl} alt="" />
            <div className="ml-5">
              <div className="font-bold text-sm mb-1">{option.title}</div>
              <div className="text-xs hidden md:block">
                {option.description}
              </div>
            </div>
          </div>
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          inputRef={inputElement}
          autoFocus={autoFocus}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
