import { FractalAdminUserSearchUserResponseUser } from '@fractalwagmi/fractal-admin';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { useSearchUserQuery } from 'components/user/queries';
import { Router } from 'core/router';

interface Props {
  small?: boolean;
}

export const UserSearch = ({ small }: Props) => {
  const router = useRouter();
  const [searchInput, setSearchInput] = useState('');
  const { data: results, isFetching } = useSearchUserQuery(searchInput);
  const resultUsers = results?.users ?? [];

  return (
    <Autocomplete
      size={small ? 'small' : 'medium'}
      fullWidth
      filterOptions={x => x}
      autoComplete
      autoHighlight
      blurOnSelect
      getOptionLabel={option =>
        `${option.email} (${option.username ?? 'No Username'})` ?? ''
      }
      options={resultUsers ?? []}
      loading={isFetching}
      noOptionsText="No results"
      inputValue={searchInput}
      isOptionEqualToValue={(option, value) => option.userId === value.userId}
      onChange={(
        _event: React.SyntheticEvent<Element, Event>,
        newValue?: FractalAdminUserSearchUserResponseUser | null,
      ) => {
        if (newValue?.userId) {
          router.push(`/user/${newValue.userId}${Router.USER_FPOINTS}`);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setSearchInput(newInputValue);
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.userId}>
          {option.email} ({option.username ?? 'No Username'})
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          placeholder="User search (email)"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
