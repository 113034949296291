import { Box } from '@mui/material';
import { ReactNode, useState } from 'react';

import { NavBar } from 'components/nav/bar';
import { ProjectDrawer } from 'components/nav/drawer/project-drawer';
import { UserDrawer } from 'components/nav/drawer/user-drawer';
import { NavMain } from 'components/nav/main';

export enum NavDrawerType {
  PROJECT = 'NAV_DRAWER_TYPE_PROJECT',
  USER = 'NAV_DRAWER_TYPE_USER',
}

export interface NavWrapperProps {
  children: ReactNode;
  navDrawerType: NavDrawerType;
}

export function NavWrapper({ children, navDrawerType }: NavWrapperProps) {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box display="flex">
      <NavBar handleDrawerToggle={handleDrawerToggle} />
      {navDrawerType === NavDrawerType.USER ? (
        <UserDrawer
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
        />
      ) : null}
      {navDrawerType === NavDrawerType.PROJECT ? (
        <ProjectDrawer
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
        />
      ) : null}
      <NavMain>{children}</NavMain>
    </Box>
  );
}
