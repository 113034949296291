import { Box, Drawer } from '@mui/material';
import { ReactElement } from 'react';

export const DRAWER_WIDTH = 240;

interface BaserDrawerProps {
  drawerContent: ReactElement;
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
}

export const BaseDrawer = ({
  drawerContent,
  handleDrawerToggle,
  mobileOpen,
}: BaserDrawerProps) => {
  return (
    <Box
      component="nav"
      sx={{ flexShrink: { sm: 0 }, width: { sm: DRAWER_WIDTH } }}
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: DRAWER_WIDTH,
          },
          display: { sm: 'none', xs: 'block' },
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: DRAWER_WIDTH,
          },
          display: { sm: 'block', xs: 'none' },
        }}
        open
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
};
