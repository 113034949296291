import { Chip, ChipProps, Theme, Typography, useTheme } from '@mui/material';

import { ProjectReviewLogStatus } from 'core/api/types';

export interface RenderableApprovalStatus {
  getBackgroundColor: (theme: Theme) => string;
  getTextColor: (theme: Theme) => string;
  label: string;
}

interface Props {
  approvalStatus: ProjectReviewLogStatus;
  chipProps?: ChipProps;
}

export const ApprovalStatusChip = ({ approvalStatus, chipProps }: Props) => {
  const theme = useTheme();
  const renderableStatus = getRenderableApprovalStatus(approvalStatus);

  return (
    <Chip
      {...chipProps}
      label={
        <Typography textTransform="uppercase" variant="caption">
          {renderableStatus.label}
        </Typography>
      }
      sx={{
        bgcolor: renderableStatus.getBackgroundColor(theme),
        color: renderableStatus.getTextColor(theme),
        ...chipProps?.sx,
      }}
      variant="filled"
    />
  );
};

export function getRenderableApprovalStatus(status: ProjectReviewLogStatus) {
  return RENDERABLE_APPROVAL_STATUS_MAP[status];
}

export const RENDERABLE_APPROVAL_STATUS_MAP: Record<
  ProjectReviewLogStatus,
  RenderableApprovalStatus
> = {
  [ProjectReviewLogStatus.UNKNOWN]: {
    getBackgroundColor: theme => theme.palette.info.main,
    getTextColor: theme => theme.palette.info.contrastText,
    label: 'draft',
  },
  [ProjectReviewLogStatus.IN_REVIEW]: {
    getBackgroundColor: theme => theme.palette.primary.main,
    getTextColor: theme => theme.palette.primary.contrastText,
    label: 'In review',
  },
  [ProjectReviewLogStatus.MORE_INFO]: {
    getBackgroundColor: theme => theme.palette.warning.main,
    getTextColor: theme => theme.palette.warning.contrastText,
    label: 'Missing info',
  },
  [ProjectReviewLogStatus.REJECTED]: {
    getBackgroundColor: theme => theme.palette.error.main,
    getTextColor: theme => theme.palette.error.contrastText,
    label: 'Rejected',
  },
  [ProjectReviewLogStatus.APPROVED]: {
    getBackgroundColor: theme => theme.palette.success.main,
    getTextColor: theme => theme.palette.success.contrastText,
    label: 'Live',
  },
  [ProjectReviewLogStatus.BANNED]: {
    getBackgroundColor: theme => theme.palette.error.main,
    getTextColor: theme => theme.palette.error.contrastText,
    label: 'Suspended',
  },
  [ProjectReviewLogStatus.HIDDEN]: {
    getBackgroundColor: theme => theme.palette.grey[400],
    getTextColor: theme => theme.palette.background.default,
    label: 'Hidden',
  },
};
