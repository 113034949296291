import { FractalAdminUserSearchUserResponseUser } from '@fractalwagmi/fractal-admin';
import { Skeleton, Stack, StackProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { Clipboard } from 'components/core/copy';
import { useUserById } from 'components/user/queries';
import { isNullOrUndefined } from 'lib/util/guards';

export interface UserInfoProps extends StackProps {
  userId: string;
}

export const UserInfo = ({ userId, ...stackProps }: UserInfoProps) => {
  const { data: user } = useUserById(userId);
  const initials = user ? getInitials(user) : '';

  return (
    <Stack spacing={4} px={2} py={6} {...stackProps}>
      {isNullOrUndefined(user) ? (
        <UserInfoSkeletonContent />
      ) : (
        <>
          <Stack alignItems="center">
            <Stack
              width="6.25rem"
              height="6.25rem"
              borderRadius="50%"
              bgcolor="secondary.main"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="h4"
                fontWeight="700"
                color="background.default"
              >
                {initials.toUpperCase()}
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <LabelValue label="Username" value={user.username} />
            <LabelValue label="Email" value={user.email} />
            <LabelValue label="User ID" value={userId} />
          </Stack>
        </>
      )}
    </Stack>
  );
};

const UserInfoSkeletonContent = () => {
  return (
    <>
      <Stack alignItems="center">
        <Skeleton
          variant="circular"
          width="6.25rem"
          height="6.25rem"
        ></Skeleton>
      </Stack>
      <LabelValue
        label={<Skeleton variant="rounded" height="1rem" width="4rem" />}
        value={<Skeleton variant="rounded" height="1.6rem" width="8rem" />}
      />
      <LabelValue
        label={<Skeleton variant="rounded" height="1rem" width="4rem" />}
        value={<Skeleton variant="rounded" height="1.6rem" width="8rem" />}
      />
    </>
  );
};

const LabelValue = ({
  label,
  value = '---',
}: {
  label: ReactNode;
  value: ReactNode;
}) => {
  return (
    <Stack>
      {typeof label === 'string' ? (
        <Typography variant="caption" color="text.secondary">
          {label}
        </Typography>
      ) : (
        label
      )}
      {typeof value === 'string' ? (
        <Clipboard text={value} copyText={value} />
      ) : (
        value
      )}
    </Stack>
  );
};

function getInitials(user: FractalAdminUserSearchUserResponseUser): string {
  if (user.firstName && user.lastName) {
    return `${user.firstName[0]}${user.lastName[1]}`;
  }
  if (user.username) {
    return `${user.username[0]}${user.username[1]}`;
  }
  if (user.email) {
    return `${user.email[0]}${user.email[1]}`;
  }
  if (user.userId) {
    return `${user.userId[0]}${user.userId[1]}`;
  }
  return 'NA';
}
