import { RequestParams } from '@fractalwagmi/fractal';
import { FractalEventsEvent } from '@fractalwagmi/fractal/fractal';
import {
  FractalAdminGamediscoveryGetRsvpUsersForEventResponseUser,
  FractalAdminGamediscoveryGetTournamentEventResponseEvent,
  FractalAdminTournamentGetTournamentTypeResponse,
} from '@fractalwagmi/fractal-admin';
import { FractalAdminGamediscoveryGetEventListResponseEvent } from '@fractalwagmi/fractal-admin/fractal-admin';
import { getAuth, getIdToken } from 'firebase/auth';
import { flatten } from 'lodash';

import {
  adminApiClient,
  bathhausApiClient,
  mainApiClient,
} from 'core/api/client';
import { GenericErrors } from 'core/api/errors/generic';
import {
  AirdropStatus,
  Collection,
  CollectionFilter,
  CollectionContract,
  Launch,
  Mission,
  ParachuteAirdropSetting,
  ProjectStats,
  ProjectV2,
  ScoreType,
  RecentScore,
  TournamentFullLeaderboard,
  PendingProjectReview,
  ProjectReviewLog,
  Studio,
  BathhausStudio,
  Tournament,
  UpcomingTournament,
  ExtendedUpcomingMission,
  UnfulfilledClaim,
} from 'core/api/types';
import { isNotNullOrUndefined } from 'lib/util/guards';

export enum ENDPOINTS {
  GET_ACTIVE_TASK = 'GET_ACTIVE_TASK',
  GET_AIRDROPS_FOR_PROJECT = 'GET_AIRDROPS_FOR_PROJECT',
  GET_UNFULFILLED_REWARDS = 'GET_UNFULFILLED_REWARDS',
  GET_CANDY_MACHINE_CONFIG = 'GET_CANDY_MACHINE_CONFIG',
  GET_COLLECTION = 'GET_COLLECTION',
  GET_COLLECTION_FILTERS = 'GET_COLLECTION_FILTERS',
  GET_COLLECTION_CONTRACTS = 'GET_COLLECTION_CONTRACTS',
  GET_COLLECTIONS = 'GET_COLLECTIONS',
  GET_EXTENDED_UPCOMING_MISSIONS = 'GET_UPCOMING_MISSIONS',
  GET_HOMEPAGE_HERO_CAROUSEL = 'GET_HOMEPAGE_HERO_CAROUSEL',
  GET_LAUNCH = 'GET_LAUNCH',
  GET_MISSION = 'GET_MISSION',
  GET_MISSIONS = 'GET_MISSIONS',
  GET_PARACHUTE_AIRDROP_SETTINGS = 'GET_PARACHUTE_AIRDROP_SETTINGS',
  GET_PENDING_PROJECT_REVIEWS = 'GET_PENDING_PROJECT_REVIEWS',
  GET_PROJECT = 'GET_PROJECT',
  GET_PROJECT_BY_ID = 'GET_PROJECT_BY_ID',
  GET_PROJECTS = 'GET_PROJECTS',
  GET_PROJECTS_BY_ID = 'GET_PROJECTS_BY_ID',
  GET_PROJECT_REVIEW_LOGS = 'GET_PROJECT_REVIEW_LOGS',
  GET_PROJECT_STATS = 'GET_PROJECT_STATS',
  GET_STUDIO_BY_PROJECT_HANDLE = 'GET_STUDIO_BY_PROJECT_HANDLE',
  SEARCH_STUDIOS = 'SEARCH_STUDIOS',
  GET_TOURNAMENTS = 'GET_TOURNAMENTS',
  GET_TOURNAMENT = 'GET_TOURNAMENT',
  GET_TOURNAMENTS_BY_PROJECT_TOURNAMENT_ID = 'GET_TOURNAMENTS_BY_PROJECT_TOURNAMENT_ID',
  GET_TOURNAMENT_SCORE_TYPES = 'GET_TOURNAMENT_SCORE_TYPES',
  GET_TOURNAMENT_SCORES_TOTALS = 'GET_TOURNAMENT_SCORES_TOTALS',
  GET_UPCOMING_TOURNAMENTS = 'GET_UPCOMING_TOURNAMENTS',
  IS_COLLECTION_RARITY_CALCULATED = 'IS_COLLECTION_RARITY_CALCULATED',
  GET_FEATURED_EVENTS = 'GET_FEATURED_EVENTS',
  GET_ALL_EVENTS = 'GET_ALL_EVENTS',
  GET_DISCOVERY_FILTER_OPTIONS = 'GET_DISCOVERY_FILTER_OPTIONS',
  GET_DISCOVERY_GAME_DETAILS = 'GET_DISCOVERY_GAME_DETAILS',
  GET_TOURNAMENT_EVENT = 'GET_TOURNAMENT_EVENT',
  GET_TOURNAMENT_TYPE = 'GET_TOURNAMENT_TYPE',
}

export interface ProjectTournamentId {
  projectId: string;
  tournamentId: string;
}

export const getRequestParams = async (): Promise<RequestParams> => {
  const user = getAuth().currentUser;
  if (!user) {
    throw new Error(GenericErrors.NO_USER);
  }
  const idToken = await getIdToken(user);
  return {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
};

export const getPendingProjectReviews = async (): Promise<
  PendingProjectReview[] | undefined
> =>
  (
    await adminApiClient.v2.getPendingProjectReviewLogs(
      await getRequestParams(),
    )
  ).data.reviews;

export const getProjectFetcher = async (
  handle: string,
): Promise<ProjectV2 | undefined> =>
  (await adminApiClient.v2.getProjectByHandle(handle, await getRequestParams()))
    .data.project;

export const getProjectsByHandleFetcher = async (
  handles: string[],
): Promise<ProjectV2[]> =>
  Promise.all(handles.map(getProjectFetcher)).then(projects =>
    projects.filter(isNotNullOrUndefined),
  );

export const getProjectByIdFetcher = async (
  projectId: string,
): Promise<ProjectV2 | undefined> =>
  (
    await bathhausApiClient.project.getProjectById(
      projectId,
      await getRequestParams(),
    )
  ).data.project;

export const getStudioByIdFetcher = async (
  studioId: string,
): Promise<BathhausStudio | undefined> =>
  (await bathhausApiClient.studio.getStudio(studioId, await getRequestParams()))
    .data.studio;

export const getProjectsByIdFetcher = async (
  projectIds: string[],
): Promise<ProjectV2[]> =>
  Promise.all(projectIds.map(async pid => getProjectByIdFetcher(pid))).then(
    projects => flatten(projects.filter(isNotNullOrUndefined)),
  );

export const getProjectReviewLogs = async (
  projectId: string,
): Promise<ProjectReviewLog[]> =>
  (
    await bathhausApiClient.project.getProjectReviewLogsForProjectId(
      projectId,
      await getRequestParams(),
    )
  ).data.reviewLogs;

export const searchStudiosFetcher = async (
  query: string,
): Promise<Studio[]> => {
  if (query === '') {
    return [];
  }

  return (
    await adminApiClient.v2.searchStudios(
      {
        query,
      },
      await getRequestParams(),
    )
  ).data.studios;
};

export const getCollectionsFetcher = async (
  projectId: string,
): Promise<Collection[] | undefined> =>
  (
    await bathhausApiClient.project.getCollectionsByProjectId(
      projectId,
      await getRequestParams(),
    )
  ).data.collections;

export const getCollectionFetcher = async (
  collectionId: string,
): Promise<Collection | undefined> =>
  (
    await bathhausApiClient.collection.getCollection(
      collectionId,
      await getRequestParams(),
    )
  ).data.collection;

export const getProjectStatsFetcher = async (
  projectId: string,
): Promise<ProjectStats | undefined> =>
  (await adminApiClient.v2.projectStats(projectId, await getRequestParams()))
    .data;

export const getMissionsFetcher = async (
  projectId: string,
): Promise<Mission[]> =>
  (
    await adminApiClient.missions.listMissions(
      {
        projectId: projectId,
      },
      await getRequestParams(),
    )
  ).data.missions;

export const getExtendedUpcomingMissionsFetcher = async (): Promise<
  ExtendedUpcomingMission[]
> =>
  (
    await adminApiClient.v2.getExtendedUpcomingMissions(
      await getRequestParams(),
    )
  ).data.missions;

export const getExtendedMissionFetcher = async (
  missionId: string,
): Promise<Mission> =>
  (
    await adminApiClient.missions.getExtendedMission(
      missionId,
      await getRequestParams(),
    )
  ).data.mission;

export const deleteMissionMutation = async (
  missionId: string,
): Promise<void> => {
  await adminApiClient.missions.deleteMission(
    missionId,
    await getRequestParams(),
  );
};

export const getLaunchFetcher = async (
  launchId: string,
  missionId: string,
): Promise<Launch> =>
  (
    await adminApiClient.launches.getLaunch(
      launchId,
      {
        missionId,
      },
      await getRequestParams(),
    )
  ).data.launch;

export const deleteLaunchMutation = async (
  missionId: string,
  launchId: string,
): Promise<void> => {
  await adminApiClient.launches.deleteLaunch(
    launchId,
    { missionId },
    await getRequestParams(),
  );
};

export const getDropCandyMachineConfigFetcher = async (
  dropId: string,
  launchId: string,
  missionId: string,
): Promise<string> => {
  return (
    await adminApiClient.drops.getCandyMachineConfig(
      dropId,
      launchId,
      missionId,
      await getRequestParams(),
    )
  ).data.configJson;
};

export const getCollectionFiltersFetcher = async (
  collectionId: string,
): Promise<CollectionFilter[] | undefined> =>
  (
    await bathhausApiClient.collection.getCollectionFilters(
      collectionId,
      await getRequestParams(),
    )
  ).data.collectionFilters;

export const getCollectionContractsFetcher = async (
  collectionId: string,
): Promise<CollectionContract[] | undefined> =>
  (
    await bathhausApiClient.collection.getCollectionContracts(
      collectionId,
      await getRequestParams(),
    )
  ).data.collectionContracts;

export const getCollectionIsRarityCalculatedFetcher = async (
  collectionId: string,
): Promise<boolean | undefined> =>
  (
    await adminApiClient.v2.collectionIsRarityCalculated(
      collectionId,
      await getRequestParams(),
    )
  ).data.isCalculated;

export const getActiveTaskFetcher = async (
  queueName: string,
  taskId: string,
): Promise<string | undefined> =>
  (await adminApiClient.v2.getTask(queueName, taskId, await getRequestParams()))
    .data.name;

export const getTournamentsForProjectFetcher = async (
  projectId: string,
): Promise<Tournament[]> =>
  (
    await adminApiClient.v2.listTournaments(projectId, await getRequestParams())
  ).data.tournaments.sort((a, b) => {
    return new Date(a.startTime ?? '0') > new Date(b.startTime ?? '0') ? -1 : 1;
  });

export const getTournamentFetcher = async (
  projectId: string,
  tournamentId: string,
): Promise<Tournament | undefined> =>
  (
    await adminApiClient.v2.getTournament(
      projectId,
      tournamentId,
      await getRequestParams(),
    )
  ).data.tournament;

export const getTournamentsFetcher = async (
  projectTournamentIds: ProjectTournamentId[],
): Promise<Tournament[]> =>
  Promise.all(
    projectTournamentIds.map(async pti =>
      getTournamentFetcher(pti.projectId, pti.tournamentId),
    ),
  ).then(tournaments => tournaments.filter(isNotNullOrUndefined));

export type TournamentTypeData =
  FractalAdminTournamentGetTournamentTypeResponse;

export const getTournamentTypeFetcher = async (tournamentId: string) =>
  (
    await adminApiClient.tournament.getTournamentType(
      tournamentId,
      await getRequestParams(),
    )
  ).data;

export const getUpcomingTournamentsFetcher = async (): Promise<
  UpcomingTournament[] | undefined
> =>
  (await mainApiClient.v1.getTournamentsUpcoming(await getRequestParams())).data
    .upcomingTournaments;

export const getAllEventsFetcher = async (): Promise<
  FractalEventsEvent[] | undefined
> =>
  (await mainApiClient.events.getUpcomingEvents(await getRequestParams())).data
    .events;

export const getTournamentEventFetcher = async (
  tournamentId: string,
): Promise<
  FractalAdminGamediscoveryGetTournamentEventResponseEvent | undefined
> =>
  (
    await adminApiClient.tournament.getTournamentEvent(
      tournamentId,
      await getRequestParams(),
    )
  ).data.event;

export const getFeaturedEventsFetcher = async (): Promise<
  FractalAdminGamediscoveryGetEventListResponseEvent[] | undefined
> =>
  (
    await adminApiClient.events.getEventList(
      'FEATURED',
      await getRequestParams(),
    )
  ).data.events;

export const getDiscoveryFilterOptions = async () =>
  (await mainApiClient.gamediscovery.getFilterOptions(await getRequestParams()))
    .data.filterOptions;

export const getDiscoveryGameDetails = async (projectId: string) =>
  (
    await mainApiClient.gamediscovery.getGameDetails(
      projectId,
      await getRequestParams(),
    )
  ).data;

export const deleteTournamentMutation = async (
  projectId: string,
  tournamentId: string,
): Promise<void> => {
  await adminApiClient.v2.deleteTournament(
    projectId,
    tournamentId,
    await getRequestParams(),
  );
};

export const getRsvpUsersForEventFetcher = async (
  eventId: string,
): Promise<FractalAdminGamediscoveryGetRsvpUsersForEventResponseUser[]> =>
  (
    await adminApiClient.event.getRsvpUsersForEvent(
      eventId,
      await getRequestParams(),
    )
  ).data.users;

export const getTournamentScoreTypesFetcher = async (
  projectId: string,
  tournamentId: string,
): Promise<ScoreType[]> =>
  (
    await adminApiClient.v2.getTournamentScoreTypes(
      projectId,
      tournamentId,
      await getRequestParams(),
    )
  ).data.scoreTypes;

export const getTournamentRecentScoresFetcher = async (
  projectId: string,
  tournamentId: string,
): Promise<RecentScore[]> =>
  (
    await adminApiClient.v2.getRecentScoresByTournament(
      projectId,
      tournamentId,
      {},
      await getRequestParams(),
    )
  ).data.recentScores;

export const getTournamentFullLeaderboardWithUserInfosFetcher = async (
  projectId: string,
  tournamentId: string,
): Promise<TournamentFullLeaderboard[]> =>
  (
    await adminApiClient.v2.getTournamentFullLeaderboardWithUserInfo(
      projectId,
      tournamentId,
      await getRequestParams(),
    )
  ).data.leaderboard;

export const getAirdropsForProjectFetcher = async (
  projectId: string,
): Promise<AirdropStatus[]> => {
  return (
    await adminApiClient.v2.getAirdropStatusForProject(
      projectId,
      await getRequestParams(),
    )
  ).data.airdropStatuses;
};

export const getParachuteAirdropSettingsForProjectFetcher = async (
  projectId: string,
): Promise<ParachuteAirdropSetting[]> => {
  return (
    await adminApiClient.v2.getParachuteAirdropSettingsForProject(
      projectId,
      await getRequestParams(),
    )
  ).data.parachuteAirdropSettings;
};

export const getUnfulfilledRewards = async (): Promise<UnfulfilledClaim[]> => {
  return (await adminApiClient.rewards.getUnfulfilled(await getRequestParams()))
    .data.unfulfilledClaims;
};
