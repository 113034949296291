import { Box, Toolbar } from '@mui/material';

import { DRAWER_WIDTH } from 'components/nav/drawer/base-drawer';
import { ChildrenProps } from 'core/dom/types';

export const NavMain = ({ children }: ChildrenProps) => (
  <Box
    component="div"
    sx={{
      flexGrow: 1,
      p: 3,
      width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
    }}
  >
    <Toolbar />
    {children}
  </Box>
);
