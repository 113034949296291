import { GoogleRpcStatus } from '@fractalwagmi/fractal';
import * as Sentry from '@sentry/nextjs';

import { isHttpResponse } from 'core/api/is-http-response';
import { isNotNullOrUndefined } from 'lib/util/guards';

type Extras = Record<string, string | number | boolean>;

export const captureFractalException = (err: unknown, extra: Extras = {}) => {
  if (err instanceof FractalError) {
    logError(err, { errorName: err.name });
    return;
  }

  if (err instanceof Error) {
    logError(err, extra);
    return;
  }

  if (typeof err === 'string') {
    logErrorAsString(err, extra);
    return;
  }

  if (err instanceof Response) {
    logErrorAsResponse(err, extra);
    return;
  }

  logErrorWithFallback(err, extra);
};

const logErrorAsString = (err: string, extra: Extras) => {
  const wrapped = new Error(err);
  logError(wrapped, extra);
};

const logErrorAsResponse = (err: Response, extra: Extras) => {
  if (!isHttpResponse(err)) {
    logErrorAsString(
      `[NetworkError] Unknown response type (no data/error fields): ${JSON.stringify(
        err,
      )}`,
      extra,
    );
    return;
  }

  const serverError = err.error;
  if (!isFractalServerError(serverError)) {
    logErrorAsString(
      `[NetworkError] Unable to parse server error ${JSON.stringify(
        serverError,
      )}`,
      extra,
    );
    return;
  }

  const errorMessage = serverError.message;
  const wrapped = new Error(errorMessage);
  logError(wrapped, extra);
};

const logErrorWithFallback = (err: unknown, extra: Extras) => {
  const errorMessage = `Unable to properly capture exception from err. (typeof: ${typeof err}) (stringified error: ${JSON.stringify(
    err,
  )})`;
  console.error(errorMessage, extra);
  Sentry.captureMessage(errorMessage, { extra });
};

const logError = (err: Error, extra: Extras) => {
  console.error(err, extra);
  Sentry.captureException(err, { extra });
};

export interface FractalErrorArgs {
  message: string;
  serverError?: GoogleRpcStatus;
}

export class FractalError extends Error {
  name: string;
  serverError?: GoogleRpcStatus;

  constructor({ message, serverError }: FractalErrorArgs) {
    super(message);
    this.name = 'FractalError';
    // Structure matches GRPC error returned by server so that we can pass
    // it through with our own wrapped errors for better observability
    this.serverError = serverError;

    // 👇️ because we are extending a built-in class
    Object.setPrototypeOf(this, FractalError.prototype);
  }
}

function isFractalServerError(err: unknown): err is GoogleRpcStatus {
  return (
    isNotNullOrUndefined(err) &&
    err !== null &&
    typeof err === 'object' &&
    'message' in err
  );
}
