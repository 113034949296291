import { SnackbarProps } from '@mui/material';
import { useCallback } from 'react';

import { useSnackbarStore } from 'components/snackbar/snackbar-store';

export const useShowSnack = () => {
  const setProps = useSnackbarStore(state => state.setProps);

  return useCallback(
    (messageOrProps: string | SnackbarProps) => {
      if (typeof messageOrProps === 'string') {
        setProps({
          message: messageOrProps,
        });
        return;
      }
      setProps({
        ...messageOrProps,
      });
    },
    [setProps],
  );
};
