import { Snackbar, SnackbarProps } from '@mui/material';

import { useSnackbarStore } from 'components/snackbar/snackbar-store';
import { secondsInMs } from 'core/time';

export const DEFAULT_SNACKBAR_PROPS: Partial<SnackbarProps> = {
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'bottom',
  },
  autoHideDuration: secondsInMs(3),
};

/**
 * A component responsible for rendering the latest snackbar props from
 * `snackbar-store`. This should be rendered once in the application, and any
 * subsequent usages of snackbar can occur through the `useShowSnack` hook.
 */
export const GlobalSnackbar = () => {
  const [snackbarProps, setSnackbarProps] = useSnackbarStore(state => [
    state.props,
    state.setProps,
  ]);

  const closeSnackbar = () => {
    setSnackbarProps(null);
  };

  return (
    <Snackbar
      {...DEFAULT_SNACKBAR_PROPS}
      {...snackbarProps}
      onClose={closeSnackbar}
      open={Boolean(snackbarProps)}
    />
  );
};
