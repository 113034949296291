import { ContentCopyRounded } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { ReactNode } from 'react';

import { useShowSnack } from 'components/snackbar/use-show-snack';

interface Props {
  copyText: string;
  iconSize?: 'small' | 'inherit' | 'medium' | 'large';
  text?: ReactNode;
}

export function Clipboard({ copyText, iconSize, text }: Props) {
  const showSnack = useShowSnack();

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {text ? <span>{text}</span> : null}
      <IconButton
        size="small"
        sx={{ color: 'text.secondary' }}
        onClick={e => {
          e.stopPropagation();
          navigator.clipboard.writeText(copyText);
          showSnack(`Copied: ${copyText}`);
        }}
      >
        <ContentCopyRounded fontSize={iconSize} />
      </IconButton>
    </Stack>
  );
}
