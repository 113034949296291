import type {} from '@mui/x-data-grid/themeAugmentation';
import { createTheme } from '@mui/material';

const COLOR_BACKGROUND_DEFAULT = '#000';
const COLOR_BACKGROUND_PAPER = '#111111';
export const DEFAULT_SPACING = 3;

export default createTheme({
  components: {
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          alignItems: 'center',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          '&:focus, &:focus-within': {
            outline: 'none',
          },
        },
        columnHeader: {
          '&:focus, &:focus-within': {
            outline: 'none',
          },
        },
        row: {
          '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.08) !important',
          },
          '&:nth-child(odd)': {
            backgroundColor: COLOR_BACKGROUND_PAPER,
          },
        },
      },
    },
    // MuiButton: {
    //   defaultProps: {
    //     disableElevation: true,
    //   },
    //   styleOverrides: {
    //     root: {
    //       borderRadius: '10px',
    //     },
    //     sizeLarge: {
    //       fontWeight: 700,
    //       lineHeight: 21 / 16,
    //       padding: '0.875rem 1.2rem',
    //     },
    //   },
    // },
    // MuiButtonBase: {
    //   defaultProps: {
    //     disableTouchRipple: true,
    //   },
    // },
  },
  palette: {
    background: {
      default: COLOR_BACKGROUND_DEFAULT,
      paper: COLOR_BACKGROUND_PAPER,
    },
    error: {
      main: '#F23D4C',
    },
    mode: 'dark',
    primary: {
      light: '#F2059F',
      main: '#cc0486',
    },
    secondary: {
      main: '#f6d860',
    },
    success: {
      main: '#009485',
    },
    warning: {
      main: '#ff9900',
    },
  },
  typography: {
    fontFamily: [
      'Roboto Mono',
      'Roboto',
      '-apple-system',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});
