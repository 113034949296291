import { TextFieldProps, TextField } from '@mui/material';
import React from 'react';

import { enterBlur } from 'core/dom/events';

export const Field = React.forwardRef((props: TextFieldProps, ref) => (
  <TextField
    onKeyDown={props.multiline ? undefined : enterBlur}
    {...props}
    inputRef={ref}
  />
));

Field.displayName = 'Field';
