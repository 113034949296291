import 'styles/global.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto-mono/300.css';
import '@fontsource/roboto-mono/400.css';
import '@fontsource/roboto-mono/500.css';
import '@fontsource/roboto-mono/700.css';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { AuthGuard } from 'components/auth/guard';
import { AuthProvider } from 'components/auth/provider';
import { ProjectLayout } from 'components/layout/project-layout';
import { GlobalSnackbar } from 'components/snackbar/global-snackbar';
import type { AppPropsWithLayout } from 'lib/nextjs';
import theme from 'styles/material';

const queryClient = new QueryClient();

function Hottub({ Component, pageProps }: AppPropsWithLayout) {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const getLayout =
    Component.getLayout ?? (page => <ProjectLayout>{page}</ProjectLayout>);

  return (
    mounted && (
      <AuthProvider>
        <AuthGuard>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CssBaseline />
                {getLayout(<Component {...pageProps} />)}
                <GlobalSnackbar />
              </LocalizationProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </AuthGuard>
      </AuthProvider>
    )
  );
}

export default Hottub;
