import { HotTubRounded } from '@mui/icons-material';
import Image from 'next/image';
import Link from 'next/link';

import { LOGO_URL } from 'core/media/constants';
import { Router } from 'core/router';

interface Props {
  mobileClasses?: string;
}

export const GoHome = ({ mobileClasses }: Props) => (
  <Link href={Router.HOME} passHref>
    <div
      className={`items-center justify-center w-full cursor-pointer flex ${mobileClasses}`}
    >
      <Image src={LOGO_URL} alt="" width="100" height="31" />
      <HotTubRounded sx={{ ml: 1 }} />
    </div>
  </Link>
);
