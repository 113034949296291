import { CircularProgress } from '@mui/material';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

import { AuthContext } from 'components/auth/context';
import { FullScreen } from 'components/core/full-screen';
import { ChildrenProps } from 'core/dom/types';
import { Router } from 'core/router';

export function AuthGuard({ children }: ChildrenProps) {
  const { loading, user } = useContext(AuthContext);
  const router = useRouter();

  useEffect(() => {
    if (!loading && !user && router.pathname !== Router.HOME) {
      router.push(Router.HOME);
    }
  }, [router, user, loading]);

  return loading ? (
    <FullScreen>
      <CircularProgress />
    </FullScreen>
  ) : (
    <>{children}</>
  );
}
