import { SnackbarProps } from '@mui/material';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type PartialSnackbarProps = Partial<Omit<SnackbarProps, 'onClose' | 'open'>>;

interface SnackbarState {
  props: PartialSnackbarProps | null;
  setProps: (newProps: PartialSnackbarProps | null) => void;
}

export const useSnackbarStore = create<SnackbarState>()(
  devtools(set => ({
    props: null,
    setProps: (newProps: PartialSnackbarProps | null) =>
      set({ props: newProps }),
  })),
);
